*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 21px;
}

@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');


/* .home-page {
    background-image: url("../assets/images/pexels-mikechie-esparagoza-1742370.jpg");
} */
.search-results{
  color:white;
  font-weight: 100;
  font-size: 66px;
}
.App {
    text-align: center;
    background: url("../assets/images/pexels-mikechie-esparagoza-1742370.jpg") no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }

.form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 10px;
    background-color: rgb(207, 220, 253);
    border-radius:  20px;
}

.quote-image {
    width: 400px;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

.centered-container h1, h2{
    color: #ffffff;
    font-size: 70px;
    margin-top: 30px;
    font-weight: 700;
    text-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
}



  @keyframes rotate{
    0% {
        transform:rotate(0);
    }
    100% {
        transform:rotate(-360deg) ;
    }
  }

  @keyframes shake {
    0% {
        transform:translateX(0);
    }
    10% {
        transform: translateX(-5px) rotate(-5deg);
    }
    20% {
        transform: translateX(5px) rotate(5deg);
    }
    30% {
        transform: translateX(-5px) rotate(-5deg);
    }
    40% {
        transform: translateX(5px) rotate(5deg);
    }
    50% {
        transform: translateX(-5px) rotate(-5deg);
    }
    60% {
        transform: translateX(5px) rotate(5deg);
    }
    70% {
        transform: translateX(-5px) rotate(-5deg);
    }
    80% {
        transform: translateX(5px) rotate(5deg);
    }
    90%{
        transform: translateX(-5px) rotate(-5deg);
    }
    100% {
        transform: translateX(0)rotate(0);
    }
  }
  
  .centered-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    animation: rotate 10s infinite linear;
  }

  .centered-image:hover {
    cursor: pointer;
    animation: shake 0.9s;
  }
  .bg-body-tertiary{
    height: 70px;
    margin:auto;
  }


  .nav-logo {
    max-width: 40px;
  }

  .library-container {
    max-width: 1200px;
    margin: 70px auto;
  }

  
  .footer-container {
    padding: 10px;
    background-color: gray;
    color: #ccc;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 60px;
  }

  .copyright{
    display: flex;
    position: fixed;
    text-align: left;
    margin-top: auto;
  }
  
  .socials {
    display: flex;
    vertical-align: middle;
    justify-content: center;
  }
  
  .socials ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .socials li {
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
  }

  .socials a {
    width: 35px;
    height: 35px;
    background: #fff;
    color: #030303;
    border-radius: 50%;
    display: block;
    text-align: center;
    padding: 2px;
    transition: background 0.3s linear, color 0.3s linear;
    -webkit-transition: background 0.3s linear, color 0.3s linear;
  }
  
  .socials a:hover {
    background: #2e2929;
    color: #fff;
  }
  
  .socials .fab {
    line-height: 2;
  }
  
  .go-top {
    width: 50px;
    height: 50px;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #141688;
    border-radius: 3px;
    color: #fff;
    border: 0;
  }
  
  .go-top:before {
    width: 13px;
    height: 13px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    content: '';
    border-style: solid;
    border-color: #fff;
    border-width: 1px 1px 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3px 0 0 -6px;
  }
  
  .go-top:hover {
    background: #f41a1a;
  }

  .save-download{
    margin-bottom: 70px;
    padding: 10px;
  }

  .save-download button {
    margin-left: 30px;
  }

  .createQButton {
    margin-bottom: 30px;
  }

  .error {
    background-color: white;
    margin-bottom: 30px;
    padding: 60px;

  }

  .error a {
    text-decoration: none;
    margin-bottom: 70px;
    padding: 15px;
    border-radius: 5px;
    background-color: rgb(16, 135, 107);
    color: white;
  }

.error img {
  width: 180px;
}